import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components"
import { motion } from "framer-motion"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import { useUIState } from "../contexts/UIStateContext"
import { useNavState } from "../contexts/NavStateContext"
import ArrowNav from "../components/ArrowNav/ArrowNav"
import KontaktImage from "../svg/kontakt.svg"


const View = styled(Container)`
    padding: 0;
    min-height: calc(100vh - ${props => props.offset}px);

    @media (max-width: 768px) {
        padding-top: 1.5rem;
        min-height: calc(100vh - ${props => props.mobileoffset}px);
      }

    p, a, span{
        font-weight: bold;
        font-size: 0.8rem;
    }

    span.font-weight-normal{
        font-weight: normal
    }

    #kontakt-img {
        svg{
            width: 260px;
            height: 260px;
        }

        @media (min-width: 576px) {
            svg{
                width: 320px;
                height: 320px;
            }
        }

        @media (min-width: 768px) {
            svg{
                    width: 350px;
                    height: 350px;
            }
        }

        @media (min-width: 992px) {
            svg{
                    width: 400px;
                    height: 400px;
            }
        }

        @media (min-width: 1200px) {
            svg{
                width: 450px;
                height: 450px;
            }
        }
    }
`

const Section = styled.section`
    padding: 0px 12px;
    `

const CustomLink = styled(Link)`
    text-decoration: none;

      :hover{
          text-decoration: underline;
      }
`
const CustomAnchor = styled.a`
    text-decoration: none;

      :hover{
          text-decoration: underline;
      }
`

const KontaktTextbox = styled(Col)`

    @media (min-width: 768px) {
        padding-top: 80px;
    }

    @media (min-width: 992px) {
        padding-top: 100px;
    }

    @media (min-width: 1200px) {
        padding-top: 120px;
    }
`

const Kontakt = ({ location }) => {
    const { totalOffset, mobileoffset } = useUIState()
    const { handleItemClick, setKontaktOpen } = useNavState()

    useEffect(() => {
        setKontaktOpen(true)
    }, [])

    return(
        <Layout location={location} activeProjectType="kontakt" backgroundColor="bgOrange">
            <View
                fluid
                className="px-0 pb-5 overflow-hidden"
                offset={totalOffset}
                mobileoffset={mobileoffset}>
                <Seo title="Kontakt" type="ContactPage" description="Resonator Coop Architekturbüro + Designbüro in Aschaffenburg. Eine progressive Kooperation von Architekten und Designern für anspruchsvolle Aufgaben in den Bereichen Bauen, Planen, Gestaltung und Design." />
                <Section
                    id="kontakt"
                    className="pt-md-5"
                    as={motion.div} 
                    initial={{
                        opacity: 0
                        }}
                    animate={{
                        opacity: 1
                        }}
                    exit={{
                        opacity: 0
                        }}
                    transition={{
                        duration: 0.3
                    }}>
                    <Row>
                        <Col id="kontakt-img" md={{ span: 6, order: 2 }} lg={{ span: 4 }} xl={{ span: 3 }} className="d-flex justify-content-center align-items-md-start mb-5 mb-md-0">
                            <KontaktImage/>
                        </Col>
                        <KontaktTextbox md={{ span: 6, order: 1 }} lg={{ span: 4, offset: 3 }} xl={{ span: 5, offset: 2 }}>
                            <p>Resonatorcoop entwickelt und betreut anspruchsvolle Projekte – lokal, regional und international.
                            <br/> Wir stehen jederzeit als direkte Ansprechpartner vom Konzept bis zur Realisierung zur Verfügung.</p>
                            <div className="py-5">
                                <p>
                                Resonator Coop Architektur + Design<br/>
                                Lex Rijkers <span className="font-weight-normal">Dipl. Ing. Architekt</span><br/>
                                Nanna Hirsch <span className="font-weight-normal">Dipl. Designerin</span><br/>
                                Frohsinnstraße 15<br/>
                                63739 Aschaffenburg<br/>
                                    Telefon <CustomAnchor className="text-dark font-weight-bold" href="tel:+496021449884">+49 (0)6021 449884</CustomAnchor><br />
                                <CustomAnchor href="mailto:studio@resonatorcoop.de" className="text-white font-weight-bold">studio@resonatorcoop.de</CustomAnchor>
                                </p>
                            </div>
                            <div className="mt-3">
                                <p>
                                    <CustomLink 
                                    to="/impressum/"
                                    state={{ prevPath: location.pathname }} 
                                    onClick={(e) => handleItemClick(e, 'close-all')}
                                    className="text-white font-weight-bold">
                                        Impressum
                                    </CustomLink>
                                </p>
                                <p>
                                    <CustomAnchor 
                                    href="/pdf/agb.pdf" 
                                    className="text-white font-weight-bold"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                        Allgemeine Geschäftsbedingungen
                                    </CustomAnchor>
                                </p>
                            </div>
                        </KontaktTextbox>
                    </Row>
                    <ArrowNav location={location} backArrow bgColor="bgOrange" />
                </Section>
            </View>
        </Layout>
    )
}

export default Kontakt